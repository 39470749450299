import { useMutation } from '@tanstack/react-query';
import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Input,
  Link,
  Spinner,
  Text,
  View,
} from 'native-base';
import React, { useEffect, useState } from 'react';

import { CountryUtil } from '@waffle/common/src/util/country/CountryUtil';
import { PhoneUtil } from '@waffle/common/src/util/phone/PhoneUtil';
import { useToast } from '@waffle/ui-web';

import WaffleLogo from '../../assets/logo/waffle-logo-words-small.png';
import WaffleCountryCallingCodePicker from '../../components/WaffleCountryCallingCodePicker';
import { useSubdomainSeller } from '../../hooks/queries/useSubdomainSeller';
import buyersApiClient from '../../utils/ApiService';

const OTP_INPUT_REGEX: RegExp = /^\d{0,6}$/;
const OTP_REGEX: RegExp = /^\d{6}$/;
const RESEND_OTP_TIMEOUT_SECONDS: number = 120;

const AuthLoginPage = () => {
  const toast = useToast();

  const [countryCode, setCountryCode] = useState<CountryUtil.CountryCode>(
    CountryUtil.CountryCode.SG,
  );
  const [mobileNumberInput, setMobileNumberInput] = useState<string>('');
  const [showAuthOTPVerficationCard, setShowAuthOTPVerficationCard] =
    useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const [timerSeconds, setTimerSeconds] = useState(0);

  const { data: seller } = useSubdomainSeller();
  useEffect(() => {
    if (!!seller) {
      setCountryCode(seller.countryCode);
    }
  }, [seller]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timerSeconds <= 0) {
        clearInterval(interval);
        return;
      }

      setTimerSeconds(timerSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timerSeconds]);

  const { mutate: generateOtp, isPending: isGenerateOtpPending } = useMutation({
    mutationFn: async ({ mobileNumber }: { mobileNumber: string }) => {
      return await buyersApiClient.request({
        method: 'POST',
        url: `/auth/otp/generate`,
        data: { mobileNumber: mobileNumber },
      });
    },
    onSuccess: () => {
      setShowAuthOTPVerficationCard(true);

      // reset timer
      setTimerSeconds(RESEND_OTP_TIMEOUT_SECONDS);
    },
    onError: () => {
      setShowAuthOTPVerficationCard(false);
      toast.show({
        status: 'error',
        title: 'Failed to send OTP',
        description: 'Please wait a while and try again later!',
      });
    },
  });

  const { mutate: verifyOtp, isPending: isVerifyOtpPending } = useMutation({
    mutationFn: async ({
      otp,
      mobileNumber,
    }: {
      otp: string;
      mobileNumber: string;
    }) => {
      return await buyersApiClient.verifyOtp({
        otp: otp,
        mobileNumber: mobileNumber,
      });
    },
    onError: (err: any) => {
      const status = err.response.status;
      let errMessage = '';

      switch (status) {
        case 401: {
          errMessage = 'Invalid OTP, please try again';
          break;
        }
        case 429: {
          errMessage = 'OTP Verification Attempts Exceeded, please get new OTP';
          break;
        }
        case 400: {
          errMessage = 'OTP Expired, please get new OTP';
          break;
        }
        case 410: {
          errMessage = 'OTP Expired, please get new OTP';
          break;
        }
        default: {
          errMessage = 'Invalid OTP, please try again';
          break;
        }
      }
      toast.show({
        status: 'error',
        title: 'Failed to verify OTP',
        description: errMessage,
      });
    },
  });

  const isResendOtpEnabled = timerSeconds === 0;

  const handleContinue = ({ mobileNumber }: { mobileNumber: string }) => {
    if (
      !PhoneUtil.isValidMobileNumber({
        text: mobileNumber,
        countryCode: countryCode,
      })
    ) {
      toast.show({
        status: 'error',
        title: 'Failed to send OTP',
        description: `Your mobile number is invalid. Please enter a valid ${CountryUtil.getCountryConfig(countryCode).name} mobile number.`,
      });
    }
    generateOtp({ mobileNumber: mobileNumber });
  };

  const handleResendOtp = ({ mobileNumber }: { mobileNumber: string }) => {
    generateOtp({ mobileNumber: mobileNumber });
  };
  const handleVerifyOTP = ({
    otp,
    mobileNumber,
  }: {
    otp: string;
    mobileNumber: string;
  }) => {
    if (!OTP_REGEX.test(otp)) {
      toast.show({
        status: 'error',
        title: 'Failed to verify OTP',
        description: 'Please enter a valid 6-digit OTP',
      });
    }
    verifyOtp({ otp: otp, mobileNumber: mobileNumber });
  };

  const callingCode: PhoneUtil.CallingCode =
    PhoneUtil.getCallingCode(countryCode);

  const mobileNumber: string = `${callingCode}${mobileNumberInput}`;

  if (!seller) {
    return (
      <Center flex={1} backgroundColor={'background.100'}>
        <Center
          width={'full'}
          maxWidth={480}
          borderRadius={'3xl'}
          backgroundColor={'surface.0'}
          paddingX={'8'}
          paddingY={'16'}>
          <Spinner />
        </Center>
      </Center>
    );
  }

  return (
    <Center flex={1} backgroundColor={'background.100'}>
      <Center
        width={'full'}
        maxWidth={480}
        borderRadius={'3xl'}
        backgroundColor={'surface.0'}
        paddingX={8}
        paddingTop={12}
        paddingBottom={16}>
        {showAuthOTPVerficationCard ? (
          <Box>
            <Text variant={'header'} marginBottom={2}>
              {`Please enter the OTP sent`} &#128513;
            </Text>
            <Text
              marginBottom={6}>{`We've sent the OTP to ${mobileNumber}`}</Text>

            <Input
              value={otp}
              onChangeText={(text) => {
                if (!OTP_INPUT_REGEX.test(text)) {
                  return;
                }
                setOtp(text);
              }}
              isFullWidth
              autoFocus
              maxLength={6}
              keyboardType="phone-pad"
              variant={'outline'}
              placeholder={'Enter 6-digit OTP'}
              textAlign={'center'}
            />
            <HStack marginTop={4}>
              <Button
                flex={1}
                variant={'subtle'}
                colorScheme={'gray'}
                onPress={() =>
                  handleResendOtp({
                    mobileNumber: mobileNumber,
                  })
                }
                isDisabled={!isResendOtpEnabled}
                isLoading={isGenerateOtpPending}
                isLoadingText="Sending OTP...">
                {isResendOtpEnabled
                  ? 'Resend OTP'
                  : `Resend OTP in ${Math.floor(timerSeconds / 60)}:${(
                      timerSeconds % 60
                    )
                      .toString()
                      .padStart(2, '0')}`}
              </Button>
              <Button
                flex={1}
                onPress={() =>
                  handleVerifyOTP({
                    otp: otp,
                    mobileNumber: mobileNumber,
                  })
                }
                isLoading={isVerifyOtpPending}
                isLoadingText={'Verifying OTP...'}
                isDisabled={!OTP_REGEX.test(otp)}
                marginLeft={4}>
                Continue
              </Button>
            </HStack>
          </Box>
        ) : (
          <Box>
            <Center width={'100%'}>
              {!!seller.logoImage ? (
                <Box
                  height={'80px'}
                  width={'80px'}
                  shadow={'5'}
                  borderRadius={'md'}
                  marginBottom={4}>
                  <Image
                    resizeMode={'contain'}
                    height={'100%'}
                    width={'100%'}
                    src={seller.logoImage.thumbnailUrl}
                    alt={'Seller Logo'}
                  />
                </Box>
              ) : null}
            </Center>

            <Text variant={'header'} marginBottom={'8'} textAlign={'center'}>
              {seller ? seller.name : 'Login / Sign-Up'}
            </Text>

            <Input
              value={mobileNumberInput}
              onChangeText={(newValue) => {
                if (!PhoneUtil.isValidMobileNumberInput(newValue)) {
                  return;
                }
                setMobileNumberInput(newValue);
              }}
              autoFocus
              keyboardType={'phone-pad'}
              placeholder={'Enter Mobile Number'}
              textAlign={'center'}
              leftElement={
                <WaffleCountryCallingCodePicker
                  countryCode={countryCode}
                  onCountryCodeChange={setCountryCode}
                  marginLeft={2}
                />
              }
              marginBottom={'2'}
            />

            <Button
              onPress={() =>
                handleContinue({
                  mobileNumber: mobileNumber,
                })
              }
              isDisabled={
                !PhoneUtil.isValidMobileNumber({
                  text: mobileNumber,
                  countryCode: countryCode,
                })
              }
              isLoading={isGenerateOtpPending}
              isLoadingText="Sending OTP..."
              size={'lg'}
              width={'full'}
              marginBottom={'4'}>
              Continue
            </Button>

            <Box>
              <Text variant={'subText'}>
                {`By continuing, you agree to the `}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.wafflepos.com/legal/waffle-buyer-privacy-policy"
                  style={{ color: 'inherit' }}>
                  Waffle-Buyer Privacy Policy
                </a>
                .
              </Text>
            </Box>
          </Box>
        )}
      </Center>
      <View
        style={{
          position: 'absolute',
          alignItems: 'center',
          bottom: 6,
        }}>
        <Link href={`https://www.wafflepos.com/`} isExternal>
          <Image
            source={WaffleLogo}
            alt={'Waffle Logo'}
            resizeMode={'contain'}
            height={'10'}
            width={'100'}
          />
        </Link>
      </View>
    </Center>
  );
};

export default AuthLoginPage;
