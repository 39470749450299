import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Image,
  Input,
  Link,
  Spinner,
  Text,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { AiOutlineMail, AiOutlineMobile } from 'react-icons/ai';
import { FaExclamationCircle } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@waffle/ui-web';

import WaffleLogoWithWord from '../../../assets/logo/waffle-logo-words-small.png';
import WaffleLogo from '../../../assets/logo/waffle-logo.png';
import { useBuyerUser } from '../../../hooks/queries/useBuyerUser';
import { useSubdomainSeller } from '../../../hooks/queries/useSubdomainSeller';
import buyersApiClient from '../../../utils/ApiService';

const exclamationColor = '#f9c044';

const ProfilePage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data: seller, isError: isSellerError } = useSubdomainSeller();

  const { data: buyerUser, isError: isBuyerUserError } = useBuyerUser();

  const [givenName, setGivenName] = useState<string | undefined>(undefined);
  const [familyName, setFamilyName] = useState<string | undefined>(undefined);
  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!buyerUser) {
      return;
    }
    setGivenName(buyerUser?.givenName ?? undefined);
    setFamilyName(buyerUser?.familyName ?? undefined);
    setDateOfBirth(buyerUser?.dateOfBirth ?? undefined);
    setEmail(buyerUser?.emailAddress ?? undefined);
  }, [buyerUser]);

  const [isSaveButtonActive, setIsSaveButtonActive] = useState<
    boolean | undefined
  >(false);

  const handleSubmitBuyerUser = async () => {
    try {
      if (!buyerUser) {
        return;
      }

      // Only given name is compulsory
      if (!givenName) {
        toast.show({
          title: 'Failed to save profile',
          description: 'Please key in your name!',
          status: 'error',
        });
        return;
      }

      // If email address was supplied but in an invalid format
      const emailRegexp: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (email && !emailRegexp.test(email)) {
        toast.show({
          title: 'Failed to save profile',
          description: 'Email Address is in an invalid format!',
          status: 'error',
        });
        return;
      }

      let formattedDateObject;
      if (!buyerUser.dateOfBirth && dateOfBirth) {
        if (dateOfBirth.length === 8) {
          const day: number = parseInt(dateOfBirth.slice(0, 2));
          const month: number = parseInt(dateOfBirth.slice(2, 4));
          const year: number = parseInt(dateOfBirth.slice(-4));
          formattedDateObject = DateTime.local(year, month, day);
          if (
            !formattedDateObject.isValid ||
            formattedDateObject > DateTime.now()
          ) {
            // DOB not valid
            toast.show({
              title: 'Failed to save profile',
              description: 'Date of Birth is not valid.',
              status: 'error',
            });
            return;
          }
          // Only reaches here if DOB is 8 digit and has valid input
        } else {
          // DOB not 8 digits
          toast.show({
            title: 'Failed to save profile',
            description:
              'Date of Birth is in an invalid format. Should be DDMMYYYY.',
            status: 'error',
          });
          return;
        }
      }

      await buyersApiClient.request({
        method: 'PUT',
        url: `/buyer_users/${buyerUser.id}`,
        data: {
          buyerUser: {
            id: buyerUser.id,
            emailAddress: email || null, // don't allow them to submit empty string if they backspace
            mobileNumber: buyerUser.mobileNumber,
            givenName: givenName,
            familyName: familyName,
            dateOfBirth: buyerUser.dateOfBirth
              ? DateTime.fromISO(buyerUser.dateOfBirth).toISODate() // prevents change in timezone when re-upserting to the database
              : formattedDateObject?.isValid // check that it is not an Invalid Date
                ? formattedDateObject.toISODate()
                : undefined,
          },
        },
      });

      await queryClient.invalidateQueries({ queryKey: ['/buyer_users'] });

      toast.show({
        title: 'Profile has been saved',
        status: 'success',
      });
      setIsSaveButtonActive(false);
      navigate('/rewards');
    } catch (error) {
      toast.show({
        title: 'Failed to save profile',
        description: 'There was an error, please check the inputs again.',
        status: 'error',
      });
    }
  };

  if (isSellerError || isBuyerUserError) {
    return <Text>Encountered an error, please try again later!</Text>;
  }

  if (!seller || !buyerUser) {
    return (
      <Center flex={'auto'}>
        <Spinner />
      </Center>
    );
  }

  return (
    <Box alignItems={'center'} padding={4} height={'100%'}>
      <Box
        width={'100%'}
        maxWidth={'600px'}
        justifyContent={'space-between'}
        height={'100%'}>
        <Box marginBottom={8}>
          <Text variant={'header'}>Profile settings</Text>
          <Box
            backgroundColor={'background.0'}
            marginY={4}
            padding={4}
            borderRadius={'lg'}
            // borderColor={'yellow.300'}
            // borderWidth={1}
          >
            <HStack
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'full'}
              marginBottom={1}>
              <Text variant={'subHeader'}> My Waffle Profile</Text>
              <Image
                marginLeft={2}
                source={WaffleLogo}
                alt={'Waffle Logo'}
                resizeMode={'contain'}
                height={'10'}
                width={'10'}
              />
            </HStack>
            <Divider marginY={2} />
            <HStack marginBottom={4} justifyContent={'space-between'}>
              {/* Given Name */}
              <Box flex={1} marginRight={8}>
                <HStack alignItems={'center'}>
                  <Text marginRight={1}>Given Name</Text>
                  {!!givenName ? null : (
                    <FaExclamationCircle color={exclamationColor} />
                  )}
                </HStack>
                <Input
                  width={'100%'}
                  autoCapitalize={'words'}
                  autoCompleteType={'name'}
                  autoCorrect={false}
                  placeholder={'Jane'}
                  defaultValue={givenName}
                  onChangeText={(newGivenName) => {
                    setGivenName(newGivenName);
                    setIsSaveButtonActive(true);
                  }}
                />
              </Box>

              {/*/!* Family Name *!/*/}
              <Box flex={1}>
                <HStack alignItems={'center'}>
                  <Text marginRight={1}>Family Name</Text>
                  {!!familyName ? null : (
                    <FaExclamationCircle color={exclamationColor} />
                  )}
                </HStack>
                <Input
                  width={'100%'}
                  autoCapitalize={'words'}
                  autoCompleteType={'name'}
                  autoCorrect={false}
                  placeholder={'Doe'}
                  defaultValue={familyName}
                  onChangeText={(newFamilyName) => {
                    setFamilyName(newFamilyName);
                    setIsSaveButtonActive(true);
                  }}
                />
              </Box>
            </HStack>
            {/* Date of birth */}
            <Box>
              <HStack alignItems={'center'}>
                <Text marginRight={1}>Date of Birth</Text>
                {!!dateOfBirth ? null : (
                  <FaExclamationCircle color={exclamationColor} />
                )}
              </HStack>
              <Input
                keyboardType={'number-pad'}
                placeholder={'DDMMYYYY'}
                isDisabled={!!buyerUser.dateOfBirth}
                value={
                  buyerUser.dateOfBirth
                    ? new Date(buyerUser.dateOfBirth).toLocaleDateString()
                    : dateOfBirth
                }
                onChangeText={(newDateOfBirth) => {
                  setDateOfBirth(newDateOfBirth);
                  setIsSaveButtonActive(true);
                }}
                InputRightElement={
                  <Box marginRight={2}>
                    <MdDateRange size={25} />
                  </Box>
                }
              />
            </Box>
            <Divider marginY={4} />
            {/* Mobile Number */}
            <Box marginBottom={4}>
              <Text>Mobile Number</Text>
              <Input
                isDisabled
                value={buyerUser?.mobileNumber ?? undefined}
                InputRightElement={
                  <Box marginRight={2}>
                    <AiOutlineMobile size={25} />
                  </Box>
                }
              />
            </Box>
            {/* Email Address */}
            <Box marginBottom={8}>
              <HStack alignItems={'center'}>
                <Text marginRight={1}>Email Address</Text>
                {!!email ? null : (
                  <FaExclamationCircle color={exclamationColor} />
                )}
              </HStack>

              <Input
                width={'100%'}
                placeholder={'jane.doe@example.com'}
                autoCompleteType={'email'}
                autoCorrect={false}
                defaultValue={email}
                onChangeText={(newEmail) => {
                  setEmail(newEmail);
                  setIsSaveButtonActive(true);
                }}
                InputRightElement={
                  <Box marginRight={2}>
                    <AiOutlineMail size={25} />
                  </Box>
                }
              />
            </Box>
            <Button
              size={'lg'}
              flex={1}
              isDisabled={!isSaveButtonActive}
              onPress={handleSubmitBuyerUser}>
              Save
            </Button>
          </Box>
        </Box>
        <Box alignItems={'center'}>
          <Link href={`https://www.wafflepos.com/`} isExternal>
            <Image
              source={WaffleLogoWithWord}
              alt={'Waffle Logo'}
              resizeMode={'contain'}
              height={'10'}
              width={'100'}
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePage;
